var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Breadcrumbs',[_c('template',{slot:"action-buttons"},[(_vm.NoLicense)?_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.upload}},[_vm._v("上传")]):_vm._e()],1)],2),(_vm.NoLicense)?_c('v-alert',{staticClass:"text-left rounded-sm body-2 pa-4 content-wrapper",attrs:{"outlined":"","dense":"","text":"","type":"warning"}},[_vm._v(" 未上传授权信息，须上传授权内容，方可展示授权详细数据！"),_c('span',{staticClass:"red--text"},[_vm._v("请检查授权码匹配硬件码: "+_vm._s(_vm.hardwareKey))]),_vm._v("。 ")]):_vm._e(),(!_vm.NoLicense && _vm.authorizationInfo.product_type === 'Webexp')?_c('v-alert',{staticClass:"text-left rounded-sm body-2 pa-4 content-wrapper",attrs:{"outlined":"","dense":"","text":"","type":"info","icon":"mdi-lightbulb-on-outline"}},[_c('span',[_vm._v("授权信息")]),_c('br'),_vm._v(" 该授权附加于产品Webexp，在此基础上支持IAM系统，并详细展示关于此种授权方式的具体内容 ")]):_vm._e(),(!_vm.NoLicense && _vm.authorizationInfo.product_type === 'IAM')?_c('v-alert',{staticClass:"text-left rounded-sm body-2 pa-4 content-wrapper",attrs:{"outlined":"","dense":"","text":"","type":"info","icon":"mdi-lightbulb-on-outline"}},[_c('span',[_vm._v("授权信息")]),_c('br'),_vm._v(" 产品IAM为主要授权方式，并详细展示关于此种授权方式的具体内容 ")]):_vm._e(),(!_vm.NoLicense && _vm.authorizationInfo)?_c('v-card',{staticClass:"dense ml-6 mt-3",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("授权信息")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"unselect"},[_vm._v("授权ID：")]),_c('span',{style:({
              color: _vm.authorizationInfo.license_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.authorizationInfo.license_id || "无")+" ")])]),_c('v-col',[_c('span',{staticClass:"unselect"},[_vm._v("产品名称：")]),_c('span',{style:({
              color: _vm.authorizationInfo.licenseto ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.authorizationInfo.licenseto || "无")+" ")])]),_c('v-col',[_c('span',{staticClass:"unselect"},[_vm._v("产品类型：")]),_c('span',{style:({
              color: _vm.authorizationInfo.product_type ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.authorizationInfo.product_type || "无")+" ")])])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"unselect"},[_vm._v("授权类型：")]),_c('span',{style:({
              color: _vm.authorizationInfo.licensetype ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.authorizationInfo.licensetype || "无")+" ")])]),_c('v-col',[_c('span',{staticClass:"unselect"},[_vm._v("客户名称：")]),_c('span',{style:({
              color: _vm.authorizationInfo.product_name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.authorizationInfo.product_name || "无")+" ")])]),_c('v-col',{staticClass:"unselect"},[_c('span',[_vm._v("过期时间：")]),_c('span',{style:({
              color: _vm.expat ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.expat || "无")+" ")])])],1)],1)],1):_vm._e(),(_vm.authorizationInfo.product_type === 'IAM')?_c('v-card',{staticClass:"dense ml-6 mt-3",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("项目特征码")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("支持访问控制：")]),_vm._v(" "+_vm._s(_vm.authorizationInfo.feature.access_control === "1" ? "支持" : "不支持")+" ")]),_c('v-col',[_c('span',[_vm._v("可发布应用数量：")]),_vm._v(" "+_vm._s(_vm.authorizationInfo.feature.app_amount)+" ")]),_c('v-col',[_c('span',[_vm._v("支持国密：")]),_vm._v(" "+_vm._s(_vm.authorizationInfo.feature.guomi === "1" ? "支持" : "不支持")+" ")])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("支持HTTPS：")]),_vm._v(" "+_vm._s(_vm.authorizationInfo.feature.https === "1" ? "支持" : "不支持")+" ")]),_c('v-col',[_c('span',[_vm._v("支持IAM组件：")]),_vm._v(" "+_vm._s(_vm.authorizationInfo.feature.iam === "1" ? "支持" : "不支持")+" ")]),_c('v-col',[_c('span',[_vm._v("支持IPV6：")]),_vm._v(" "+_vm._s(_vm.authorizationInfo.feature.ipv6 === "1" ? "支持" : "不支持")+" ")])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("支持远程管理：")]),_vm._v(" "+_vm._s(_vm.authorizationInfo.feature.remote_control === "1" ? "支持" : "不支持")+" ")]),_c('v-col',[_c('span',[_vm._v("可管理Worker数量：")]),_vm._v(" "+_vm._s(_vm.authorizationInfo.feature.worker_amount)+" ")]),_c('v-col')],1)],1)],1):_vm._e(),_c(_vm.sheet_editor,{tag:"Component",attrs:{"hardwareKey":_vm.hardwareKey}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }