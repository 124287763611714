<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          授权文件上传
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" :model="form" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        请提供该硬件特征码授权用于签发授权
      </v-alert>
      <v-row class="align-start">
        <v-col cols="3" class="mt-2">
          <v-subheader style="color:rgba(0,0,0,.8)">hardware_key:</v-subheader>
        </v-col>

        <v-col cols="9" class="mt-6 text-body-2">
          <span>{{ hardwareKey }}</span>
        </v-col>
      </v-row>
      <v-row class="align-start">
        <v-col cols="2" class="mt-2">
          <v-subheader style="color:rgba(0,0,0,.7)"
            ><span class="red--text">*</span> 授权内容</v-subheader
          >
        </v-col>

        <v-col cols="10">
          <v-textarea
            class="text-caption"
            :auto-grow="true"
            rows="10"
            v-model="content"
            required
            :rules="rules.isRight"
            persistent-hint
            hint="上传授权文件内容"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex" cols="3"></v-col>
        <v-col cols="9" class="text-left">
          <v-btn elevation="4" medium color="primary" @click="save(form)"
            >提交</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "UploadContent",
  inject: ["reload"],
  props: ["hardwareKey"],
  data() {
    let isRight = value => {
      let uploadData;
      try {
        uploadData = JSON.parse(atob(value));
      } catch (err) {
        return false;
      }
      if (
        uploadData.data &&
        uploadData.sign &&
        uploadData.sign_ecdsa &&
        uploadData.sign_ed25519
      ) {
        return true;
      } else {
        return false;
      }
    };
    return {
      valid: true,
      content: "",
      form: {},
      rules: {
        isRight: [v => isRight(v) || "上传内容不符合规则"]
      }
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$http
          .post("api/license/upload", {
            content: this.content
          })
          .delegateTo(api_request)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            this.reload();
            return "上传成功";
          })
          .catch(({ code, message }) => {
            throw `上传失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
      }
    }
  }
};
</script>
