<template>
  <div>
    <Breadcrumbs>
      <template slot="action-buttons">
        <v-btn
          v-if="NoLicense"
          class="pl-5 pr-5"
          depressed
          color="primary"
          @click="upload"
          >上传</v-btn
        >
      </template>
    </Breadcrumbs>
    <v-alert
      v-if="NoLicense"
      outlined
      dense
      text
      type="warning"
      class="text-left rounded-sm body-2 pa-4 content-wrapper"
    >
      未上传授权信息，须上传授权内容，方可展示授权详细数据！<span
        class="red--text"
        >请检查授权码匹配硬件码: {{ hardwareKey }}</span
      >。
    </v-alert>

    <v-alert
      outlined
      dense
      text
      type="info"
      icon="mdi-lightbulb-on-outline"
      class="text-left rounded-sm body-2 pa-4 content-wrapper"
      v-if="!NoLicense && authorizationInfo.product_type === 'Webexp'"
    >
      <span>授权信息</span><br />
      该授权附加于产品Webexp，在此基础上支持IAM系统，并详细展示关于此种授权方式的具体内容
    </v-alert>

    <v-alert
      outlined
      dense
      text
      type="info"
      icon="mdi-lightbulb-on-outline"
      class="text-left rounded-sm body-2 pa-4 content-wrapper"
      v-if="!NoLicense && authorizationInfo.product_type === 'IAM'"
    >
      <span>授权信息</span><br />
      产品IAM为主要授权方式，并详细展示关于此种授权方式的具体内容
    </v-alert>

    <v-card
      elevation="0"
      class="dense ml-6 mt-3"
      v-if="!NoLicense && authorizationInfo"
    >
      <v-card-title>授权信息</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span class="unselect">授权ID：</span>
            <span
              :style="{
                color: authorizationInfo.license_id ? '' : '#9195a3'
              }"
            >
              {{ authorizationInfo.license_id || "无" }}
            </span>
          </v-col>
          <v-col>
            <span class="unselect">产品名称：</span>
            <span
              :style="{
                color: authorizationInfo.licenseto ? '' : '#9195a3'
              }"
            >
              {{ authorizationInfo.licenseto || "无" }}
            </span>
          </v-col>
          <v-col>
            <span class="unselect">产品类型：</span>
            <span
              :style="{
                color: authorizationInfo.product_type ? '' : '#9195a3'
              }"
            >
              {{ authorizationInfo.product_type || "无" }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col>
            <span class="unselect">授权类型：</span>
            <span
              :style="{
                color: authorizationInfo.licensetype ? '' : '#9195a3'
              }"
            >
              {{ authorizationInfo.licensetype || "无" }}
            </span>
          </v-col>
          <v-col>
            <span class="unselect">客户名称：</span>
            <span
              :style="{
                color: authorizationInfo.product_name ? '' : '#9195a3'
              }"
            >
              {{ authorizationInfo.product_name || "无" }}
            </span>
          </v-col>
          <v-col class="unselect">
            <span>过期时间：</span>
            <span
              :style="{
                color: expat ? '' : '#9195a3'
              }"
            >
              {{ expat || "无" }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      v-if="authorizationInfo.product_type === 'IAM'"
      elevation="0"
      class="dense ml-6 mt-3"
    >
      <v-card-title>项目特征码</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span>支持访问控制：</span>
            {{
              authorizationInfo.feature.access_control === "1"
                ? "支持"
                : "不支持"
            }}
          </v-col>
          <v-col>
            <span>可发布应用数量：</span>
            {{ authorizationInfo.feature.app_amount }}
          </v-col>
          <v-col>
            <span>支持国密：</span>
            {{ authorizationInfo.feature.guomi === "1" ? "支持" : "不支持" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>支持HTTPS：</span>
            {{ authorizationInfo.feature.https === "1" ? "支持" : "不支持" }}
          </v-col>
          <v-col>
            <span>支持IAM组件：</span>
            {{ authorizationInfo.feature.iam === "1" ? "支持" : "不支持" }}
          </v-col>
          <v-col>
            <span>支持IPV6：</span>
            {{ authorizationInfo.feature.ipv6 === "1" ? "支持" : "不支持" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span>支持远程管理：</span>
            {{
              authorizationInfo.feature.remote_control === "1"
                ? "支持"
                : "不支持"
            }}
          </v-col>
          <v-col>
            <span>可管理Worker数量：</span>
            {{ authorizationInfo.feature.worker_amount }}
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <Component :is="sheet_editor" :hardwareKey="hardwareKey" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import { timestampToTime } from "@/util/util";
import Breadcrumbs from "@/components/Breadcrumbs";
import UPLOAD from "@/views/setting/upload/Content";

const EDITORS = {
  UPLOAD: "UPLOAD"
};

export default {
  name: "Personalization",
  data() {
    return {
      sheet_editor: null,
      drawer: false,
      expat: null,
      NoLicense: false,
      authorizationInfo: {
        feature: {}
      },
      hardwareKey: null
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      return this.$http
        .get(`api/license/view`)
        .delegateTo(api_request)
        .then(data => {
          let { license, hardware_key, code } = data;
          if (license == null && hardware_key) {
            this.hardwareKey = hardware_key;
            this.NoLicense = true;
            this.$snackbar.showMessage({
              content: `${this.$t("api." + code)}`,
              isError: true
            });
          } else {
            this.expat = timestampToTime(license.expat);
            this.authorizationInfo = license;
          }
        })
        .catch(({ code, message }) => {
          throw `获取授权信息失败：${this.$t(
            "api." + code
          )}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    upload() {
      this.sheet_editor = EDITORS["UPLOAD"];
      setTimeout(() => this.$store.commit("show_sheet", { show: true }), 1);
    }
  },
  components: {
    Breadcrumbs,
    UPLOAD
  }
};
</script>

<style lang="less" scoped>
/deep/.v-input__prepend-outer {
  margin-right: 0 !important;
}
</style>
